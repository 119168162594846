// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-contentful-blog-title-js": () => import("./../../../src/pages/blog/{ContentfulBlog.title}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-title-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-challenge-js": () => import("./../../../src/pages/challenge.js" /* webpackChunkName: "component---src-pages-challenge-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

